import { render, staticRenderFns } from "./Tab.landlordType.vue?vue&type=template&id=2f71c232&scoped=true&lang=pug&"
import script from "./Tab.landlordType.vue?vue&type=script&lang=ts&"
export * from "./Tab.landlordType.vue?vue&type=script&lang=ts&"
import style0 from "./Tab.landlordType.vue?vue&type=style&index=0&id=2f71c232&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f71c232",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VForm } from 'vuetify/lib/components/VForm';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
installComponents(component, {VForm,VRadioGroup})
