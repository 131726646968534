var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"step"},[_c('div',{staticClass:"text-h3 secondary--text text-center mb-13",domProps:{"innerHTML":_vm._s(_vm.$t('tabs.leasingTools.title'))}}),_c('LeasingToolsIcon',{staticClass:"icon mb-4"}),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(!_vm.isManual)?_c('Select',{staticClass:"w-100",attrs:{"items":_vm.getTools,"label":_vm.$t('tabs.leasingTools.toolsPlaceholder'),"data-test-id":"leasing-select"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$store.getters.customSelectLabel(item, 'landlord-leasing-tools')))])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$store.getters.customSelectLabel(item, 'landlord-leasing-tools')))])]}},{key:"appendItem",fn:function(){return [_c('div',{staticClass:"no-tools-message",domProps:{"innerHTML":_vm._s(_vm.$t('tabs.leasingTools.noToolMessage'))},on:{"click":function($event){_vm.isManual = true}}})]},proxy:true}],null,true),model:{value:(_vm.user.leasingTool),callback:function ($$v) {_vm.$set(_vm.user, "leasingTool", $$v)},expression:"user.leasingTool"}}):_c('div',[_c('Input',{staticClass:"w-100",attrs:{"label":_vm.$t('tabs.leasingTools.toolsInputPlaceholder'),"data-test-id":"manual-property-input","error-messages":errors[0],"maxlength":"255"},model:{value:(_vm.user.manualLeasingTool),callback:function ($$v) {_vm.$set(_vm.user, "manualLeasingTool", $$v)},expression:"user.manualLeasingTool"}}),_c('div',{staticClass:"primary--text text-pointer text-center",domProps:{"innerHTML":_vm._s(_vm.$t('tabs.leasingTools.showSelectMessage'))},on:{"click":function($event){_vm.isManual = false}}})],1),_c('div',{staticClass:"text-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('Button',{staticClass:"mt-20",attrs:{"loading":_vm.loading,"color":"primary","type":"submit","data-test-id":"continue-button"}},[_vm._v(_vm._s(_vm.$t('tabs.leasingTools.button')))])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }