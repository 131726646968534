






















































import { Component, Vue } from 'vue-property-decorator';
import LeasingToolsIcon from '@/assets/icons/leasing-tools.svg';
import {IUser} from '@/services/api/user.service';
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import '@/validators/vee-validate';

import {createHelpers} from 'vuex-map-fields';
const { mapFields } = createHelpers({
  getterType: 'getUserFields',
  mutationType: 'updateUserField',
});

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    LeasingToolsIcon
  },
  computed: { ...mapFields(['user']) },
})
export default class TabLeasingTools extends Vue {
  private user!: IUser;
  private isManual: boolean = false;
  private loading: boolean = false;

  public created() {
    this.$store.dispatch('getDictionary', 'landlord-leasing-tools');
  }

  public mounted(): void {
    if (!this.user.leasingTool && this.user.manualLeasingTool) {
      this.isManual = true;
    }
  }

  private get getTools() {
    return Object.keys(this.$store.getters.getDictionary('landlord-leasing-tools')) ?? [];
  }

  private onSubmit(): void {
    this.loading = true;
    this.$store.dispatch('saveUserLeasingTools', this.isManual)
      .then(() => this.$emit('nextStep'))
      .finally(() => this.loading = false);
  }
}
