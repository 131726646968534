



















































import { Component, Vue } from 'vue-property-decorator';
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import '@/validators/vee-validate';
import {createHelpers} from 'vuex-map-fields';
import AgreementIcon from '@/assets/icons/reviewing-information-icon.svg';
import {IUser} from '@/services/api/user.service';
import DocumentLink from '@/components/Ui/DocumentLink.vue';
import {documentNamesEnum} from '@/interfaces';

const { mapFields } = createHelpers({
  getterType: 'getUserFields',
  mutationType: 'updateUserField',
});

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    AgreementIcon,
    DocumentLink
  },
  computed: { ...mapFields(['user']) },
})
export default class TabAgreement extends Vue {
  public documentNamesEnum = documentNamesEnum;
  private user!: IUser;
  private isPartnerAgreement: boolean = false;
  private loading: boolean = false;
  private showNewsError: boolean = false;
  private showPartnerError: boolean = false;

  private async onSubmit() {      
    if (!this.user.newsAgreement || !this.isPartnerAgreement) {
      this.showNewsError = !this.user.newsAgreement;
      this.showPartnerError = !this.isPartnerAgreement;
      return;
    }
    this.loading = true;

    try {
      await this.$store.dispatch('saveUserAgreement');
      this.$router.push({ name: 'congratulation' });
    } catch (error) {
      console.error('Error saving agreement:', error);
    } finally {
      this.loading = false;
    }
  }
}
