






import { Component, Vue } from 'vue-property-decorator';

@Component
export default class AccelerantLogo extends Vue {
  private logo = require('@/assets/images/PolicyDepositeAccess/accelerant-logo-sm.png');
}
