






































import { Component, Vue } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import '@/validators/vee-validate';
import PrivateLandlordIcon from '@/assets/icons/landlordTypes/private-landlord-icon.svg';
import PropertyManagerIcon from '@/assets/icons/landlordTypes/proprety-manager-icon.svg';
import RealEstateIcon from '@/assets/icons/landlordTypes/institutional-landlord-icon.svg';
import OtherIcon from '@/assets/icons/landlordTypes/cooperative-icon.svg';
import {createHelpers} from 'vuex-map-fields';
import { LandlordEnum } from '@/interfaces';
import {IUser} from '@/services/api/user.service';

const { mapFields } = createHelpers({
  getterType: 'getUserFields',
  mutationType: 'updateUserField',
});

@Component({
  components: { ValidationObserver, ValidationProvider },
  computed: { ...mapFields(['user']) },
})
export default class TabLandlordType extends Vue {
  private user!: IUser;
  private loading: boolean = false;

  private get listItem() {
    return [
      {
        value: LandlordEnum.PRIVATE,
        icon: PrivateLandlordIcon,
        title: this.$tc('tabs.landlordType.type.private')
      },
      {
        value: LandlordEnum.PROPERTY_MANAGER,
        icon: PropertyManagerIcon,
        title: this.$tc('tabs.landlordType.type.property_manager')
      },
      {
        value: LandlordEnum.INSTITUTIONAL,
        icon: RealEstateIcon,
        title: this.$tc('tabs.landlordType.type.agent')
      },
      {
        value: LandlordEnum.MUNICIPALITY,
        icon: OtherIcon,
        title: this.$tc('tabs.landlordType.type.cooperative')
      },
    ];
  }

  private onSubmit(): void {
    this.loading = true;
    this.$store.dispatch('saveUserType')
      .then(() => this.$emit('nextStep'))
      .finally(() => this.loading = false);
  }
}
